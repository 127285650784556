<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col md="3">
        <analytics-congratulation
          v-if="false"
          :data="dashboard.congratulations"
        />
      </b-col>
      <b-col md="9">
        <analytics-class-chart
          v-if="false"
          :data="dashboard"
          :meses="meses"
          :year.sync="year"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6" hidden>
        <analytics-avg-sessions v-if="false" :data="data.avgSessions" />
      </b-col>
      <b-col hidden>
        <analytics-timeline v-if="false" :data="data.timeline" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import store from "@/store/index";
import AnalyticsClassChart from "./AnalyticsClassChart.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";

export default {
  components: {
    BRow,
    BCol,
    AnalyticsClassChart,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    AnalyticsTimeline,
  },
  watch: {
    year() {
      this.fetchData();
    },
  },

  data() {
    return {
      data: {},
      dashboard: {},
      meses: [],
      year: this.$moment().year(),
    };
  },
  async created() {
    try {
      // data
      // const analytics = await this.$http.get("/analytics/data");
      // this.data = analytics.data;
      // await this.fetchData();

      await store.dispatch("account/fetch");
      await store.dispatch("client/fetch");
    } catch (err) {
      throw new Error(err.message);
    }
  },
  methods: {
    async fetchData() {
      const dashboard = await this.$http.get("/dashboard", {
        params: {
          year: this.year,
        },
      });
      this.dashboard = dashboard.data.dashboard;
      this.meses = dashboard.data.meses;
    },
  },
};
</script>
